import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';
import { Button, Row } from 'react-bootstrap';

import './index.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { CategoryCard } from '../../modules/cs/main/components/category-card';
import { Box } from '../../modules/common/components/box';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const SoloIndexPage: React.FC = () => {
  return (
    <DashboardLayout className={'home-page solo '} game="solo">
      <div className="dashboard-hero">
        <h1>Prydwen.gg - Solo Leveling: Arise wiki and tier list</h1>
        <h2>
          Prydwen.gg is a wiki for Solo Leveling: Arise. Check our guides, tier
          lists and reviews for characters available in the game.
        </h2>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <SectionHeader title="Play on PC" />
      <div className="banner countdown">
        <div className="cta">
          <h4>Play Solo Leveling on PC</h4>
          <OutboundLink
            href="https://www.ldplayer.net/games/com-netmarble-sololv-on-pc.html?n=82774712#utm_source=aff&utm_medium=aff&utm_campaign=aff82774712"
            target="_blank"
          >
            <Button variant="primary">Play now on LDPlayer</Button>
          </OutboundLink>
        </div>
      </div>
      <SectionHeader title="Active Codes" />
      <div className="codes">
        <Box isCentered>
          <p className="code">SLASUMMEREVENT</p>
          <p className="rewards">10 Custom Draw Tickets</p>
        </Box>
        <Box isCentered>
          <p className="code">HUNTERCOSTUME</p>
          <p className="rewards">1000 Costume Chroma Mats</p>
        </Box>
        <Box isCentered>
          <p className="code">SEEUAGAIN</p>
          <p className="rewards">10 Custom Draw Tickets</p>
        </Box>
        <Box isCentered>
          <p className="code">100DAYTHX</p>
          <p className="rewards">
            1m Gold, 100 Rune Fragment and 100 Powder of Blessing
          </p>
        </Box>
      </div>
      <SectionHeader title="Guides" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the game"
          link="/solo-leveling/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../images/solo/categories/category_intro.jpg"
              alt="Introduction to the game"
            />
          }
        />
        <CategoryCard
          title="Reroll guide"
          link="/solo-leveling/guides/reroll"
          image={
            <StaticImage
              src="../../images/solo/categories/category_reroll.jpg"
              alt="Reroll guide"
            />
          }
        />
        <CategoryCard
          title="Beginner guide"
          link="/solo-leveling/guides/beginner-guide"
          image={
            <StaticImage
              src="../../images/solo/categories/category_beginner.jpg"
              alt="Reroll guide"
            />
          }
        />
        <CategoryCard
          title="Best skills for Jinwoo"
          link="/solo-leveling/guides/jinwoo-skills"
          image={
            <StaticImage
              src="../../images/solo/categories/category_skill.jpg"
              alt="Best skills for Jinwoo"
            />
          }
        />
        <CategoryCard
          title="Best stats and gear for Jinwoo"
          link="/solo-leveling/guides/jinwoo-stats"
          image={
            <StaticImage
              src="../../images/solo/categories/category_stats.jpg"
              alt="Best stats for Jinwoo"
            />
          }
        />
        <CategoryCard
          title="Jinwoo's Best Blessing Stones"
          link="/solo-leveling/guides/jinwoos-best-blessing-stones"
          image={
            <StaticImage
              src="../../images/solo/categories/category_blessing.webp"
              alt="Guides"
            />
          }
        />
        <CategoryCard
          title="Hunter Tier List"
          link="/solo-leveling/tier-list"
          image={
            <StaticImage
              src="../../images/solo/categories/category_tier.jpg"
              alt="Tier List"
            />
          }
          updated
        />
        <CategoryCard
          title="Weapon Tier List"
          link="/solo-leveling/tier-list-weapons"
          image={
            <StaticImage
              src="../../images/solo/categories/category_tier2.jpg"
              alt="Tier List"
            />
          }
          updated
        />
        <CategoryCard
          title="Dupe Priorities"
          link="/solo-leveling/guides/dupe-priorities"
          image={
            <StaticImage
              src="../../images/solo/categories/category_dupe_2.png"
              alt="Dupe Priorities"
            />
          }
        />
        <CategoryCard
          title="Battlefield of Chaos"
          link="/solo-leveling/guides/battlefield-of-chaos"
          image={
            <StaticImage
              src="../../images/solo/categories/category_boc.jpg"
              alt="Battlefield of Chaos"
            />
          }
        />
        <CategoryCard
          title="Workshop of Brilliant Light"
          link="/solo-leveling/guides/workshop-of-brilliant-light"
          image={
            <StaticImage
              src="../../images/solo/categories/category_wobl.webp"
              alt="Guides"
            />
          }
        />
        <CategoryCard
          title="Artificial Dungeons"
          link="/solo-leveling/guides/artificial-dungeons"
          image={
            <StaticImage
              src="../../images/solo/categories/category_ad.webp"
              alt="Guides"
            />
          }
        />
        <CategoryCard
          title="Guilds and Guild Boss"
          link="/solo-leveling/guides/guilds-and-guild-boss"
          image={
            <StaticImage
              src="../../images/solo/categories/category_guild.webp"
              alt="Guides"
            />
          }
          isNew
        />
        <CategoryCard
          title="Characters list"
          link="/solo-leveling/characters"
          image={
            <StaticImage
              src="../../images/solo/categories/category_characters.jpg"
              alt="Characters"
            />
          }
        />
        <CategoryCard
          title="Artifact Sets"
          link="/solo-leveling/guides/artifact-sets"
          image={
            <StaticImage
              src="../../images/solo/categories/category_arti.png"
              alt="Artifact Sets"
            />
          }
        />
        <CategoryCard
          title="Artifact Stats"
          link="/solo-leveling/guides/artifact-stats"
          image={
            <StaticImage
              src="../../images/solo/categories/category_arti2.png"
              alt="Artifact Stats"
            />
          }
        />
      </Row>
      <SectionHeader title="Community" />
      <CommunitySection />
    </DashboardLayout>
  );
};

export default SoloIndexPage;

export const Head: React.FC = () => (
  <Seo
    title="Solo Leveling: Arise Wiki & Database | Prydwen Institute"
    description="Prydwen.gg is a wiki for Solo Leveling: Arise. Check our guides, tier lists and reviews for characters available in the game."
    game="solo"
  />
);
